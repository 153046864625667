.contact-info {
  /* width: 75%; */
  /* margin-left: 12rem; */
  /* height: 200px; */
  margin: 1rem 0px;
  padding: 3rem;
  gap: 2.5rem;
  position: relative;
}

.contact-info-icon {
  z-index: 100;
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-info-icon img {
  /* margin-left: 0.7rem;
  margin-left: 0.7rem;
  width: 3.7rem; */
}

.contact-info-icons {
  /* position: relative; */
  /* top: 0; */
  /* left: -11px; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  place-items: center;
  margin-top: 10px;
  gap: 35px;
}

.icon-title {
  font-size: 1rem;
  font-family: "Oswald";
  font-weight: 300;
  margin-top: 1rem;
  text-align: center;
}

.phone-number,
.address,
.email-address,
.phone-number {
  font-size: 0.7rem;
  font-family: "Oswald";
  font-weight: 200;
  margin-top: 0.5;
  color: var((--lightgrey));
  margin-top: 0.5rem;
  text-align: center;
}

.contact-info-icons {
  display: flex;
  justify-items: flex-start;
}

.icon-bottom:hover {
  border-bottom: 2px solid var(--green);
}

.icon-bottom {
  text-align: center;
  padding-bottom: 1rem;
}

/* .contact-info::after {
    content: '';
    display: block;
    position: relative;
    width: 45%;
    left: -3rem;
    border: 1px solid var(--lightgrey);
    margin-top: 3.3rem;
} */
.green-rect-position-contactInfo {
  top: 3.5rem;
  left: 26.3rem;
}

.contact-info .bottom_corner {
  position: absolute;
  /* top: 250px;
  bottom: -300px;
  left: 50px; */
}

@media (max-width: 1200px) {
  /* .contact-info .bottom_corner {
    top: 300px;
    left: 43px;
    bottom: -300px;
  } */
}

@media (max-width: 768px) {
  /* .contact-info .bottom_corner {
    top: 300px;
    left: 43px;
    bottom: -300px;
  } */

  .contact-info-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    place-items: center;
  }
}

@media (max-width: 550px) {
  .contact-info-icons {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
  }
}

.contact_us_page .bottom_corner,
.services_page .bottom_corner {
  display: none;
}
