.gallery_img_wrapper {
  margin-top: 100px;
  margin-bottom: 100px;
}

.gallery_img {
  height: 220px !important;
  object-fit: cover;
  object-position: center;
}
