.section_wrapper {
  background-color: #222;
  padding-top: 7%;
  padding-bottom: 7%;
  overflow: hidden;
}

.section {
  color: var(--lightgrey);
  position: relative;
}

.left-text-1 {
  font-family: "Oswald", sans-serif;
  font-size: 3rem;
  font-weight: 400;
}

.left-text-2,
.left-text-3,
.left-text-4 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--lightgrey);
  margin-top: 2rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: justify;
}

.left-text-2 span:hover {
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.right-section {
  background-image: url("../../../assets//interior-section-img.png");
  background-position: center;
  background-size: cover;
  width: 500px;
  height: 500px;
}

.bottom_corner {
  position: absolute;
  left: 50px;
}

@media (max-width: 1200px) {
  .left-section {
    width: 80%;
  }

  .bottom_corner {
    left: 43px;
    bottom: -270px;
  }

  .right-section {
    margin: 40px 0px;
  }
}

@media (max-width: 768px) {
  .right-section {
    width: 425px;
    height: 425px;
    margin: 10px 0px;
  }

  .bottom_corner {
    left: 43px;
    bottom: -270px;
  }
}

@media (max-width: 450px) {
  .right-section {
    width: 380px;
    height: 380px;
  }
}

@media (max-width: 440px) {
  .bottom_corner {
    display: none;
  }
}

@media (max-width: 360px) {
  .section_wrapper {
    padding-top: 100px;
  }

  .right-section {
    width: 300px;
    height: 300px;
  }
}
