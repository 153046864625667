.bottom-corner {
  width: 24.6rem;
  /* height: 13.8rem; */
  border-bottom: 2px solid var(--lightgrey);
  border-left: 2px solid var(--lightgrey);
  position: relative;
  /* top: -10.5rem;
    left: -4rem; */
  /* z-index: -1; */
}

@media (max-width: 768px) {
  .bottom-corner {
    width: 19.6rem;
  }
}

.green-rect-bottom {
  width: 5rem;
  height: 1vh;
  background-color: var(--green);
  position: relative;
  /* top: -10.75rem;
    left: 20.65rem; */
}
