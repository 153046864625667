.future-living {
  padding: 7% 0;
}

.future-living-img_wrapper {
  display: flex;
  justify-content: center;
}

.future-living-img {
  background-position: center;
  background-size: fill;
  width: 500px;
  height: 100%;
  border-radius: 5px 5px 5px 5px !important;
}

.future-living-texts-and-icons {
}

.future-living-text-1,
.future-living-text-3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--lightgrey);
  margin-top: 2rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: justify;
}

.future-living-text-1 span:hover {
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.future-living-text-2 {
  font-family: "Oswald", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  color: var(--lightgrey);
}

.icons p {
  font-size: 0.8rem;
  color: var(--lightgrey);
  height: 15vh;
  text-align: center;
  font-family: "Oswald", sans-serif;
}

.icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 10rem;
  height: 8.6rem;
  margin-top: 2rem;
}

.icons:hover {
  border-bottom: 2px solid var(--green);
}

.future-living-icons-firstline {
  display: flex;
  justify-content: space-around;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  gap: 4rem;
}

.future-living-icons-secondline {
  display: flex;
  align-items: center;
  margin-left: 2rem;
  justify-content: center;
  gap: 2.5rem;
}

@media (max-width: 768px) {
  .future-living-img {
    width: 400px;
    height: auto;
  }
}
