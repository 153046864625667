
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald:wght@200;300;400;500;600;700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Oswald&display=swap');

  *{
    box-sizing: border-box;
    margin:0;
    padding: 0;
  }

  :root {
    --white: #fff;
    --black: #000;
    --green:#61FF00;
    --grey:#555454;
    --darkgrey:#232323;
    --lightgrey:#cfcfcf;
  }
  body {
    font-family: 'Didact Gothic', sans-serif;
    background-color: #000;
    color: var(--lightgrey);
}
.project-section{
  margin-top: 6rem;
}

img{
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}