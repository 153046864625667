.top-corner {
  width: 24.6rem;
  height: 18.8rem;
  border-top: 3px solid var(--lightgrey);
  border-right: 3px solid var(--lightgrey);
  position: relative;
  top: 6.5rem;
  /* left: 60rem; */
}

.green-rect {
  width: 5rem;
  height: 1vh;
  background-color: var(--green);
  position: relative;
  top: -12.4rem;
  /* left: 56rem; */
}
.dots {
  width: 0.7rem;
  position: relative;
  top: 6.5rem;
  right: -24.1rem;
}

.green-dot {
  background-color: var(--green);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: 0.7rem;
  margin-top: 1rem;
  /* position: relative;
    top: 335px;
    left: 1329px; */
}

.white-dot {
  background-color: var(--white);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-bottom: 0.7rem;
  /* position: relative;

    left: 1329px; */
}

/* .top-position-1 {
    top: 342px;
}

.top-position-2 {
    top: 349px;
}

.top-position-3 {
    top: 356px; */
