.footer-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10vh;
  background-color: var(--black);
  margin-top: 4rem;
  margin-bottom: 4rem;
  position: relative;
}

.last-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6vh;
  padding-bottom: 1rem;
}

.footer-bar .logo {
  /* margin-left: 10%; */
}

.footer-bar .links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1.9rem;
  /* margin-right: 10%; */
}

.footer-bar a {
  color: var(--white);
  text-decoration: none;
  font-size: 0.9rem;
}

.footer-bar .links a:hover {
  color: var(--green);
}

.footer-bar .links div {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bar .links .home,
.footer-bar .links .about,
.footer-bar .links .services,
.footer-bar .links .projects,
.footer-bar .links .contact {
  color: #fff;
}

.footer-bar .links .home:hover,
.footer-bar .links .about:hover,
.footer-bar .links .projects:hover,
.footer-bar .links .contact:hover {
  margin-top: -2px;
  border-top: 2px solid var(--green);
  color: var(--green);
}

.active {
  margin-top: -2px;
  border-top: 2px solid var(--green);
  color: var(--green) !important;
}

.footer_menu {
  position: absolute;
  bottom: 40px;
  list-style: none;
  padding: 0px;
  border-radius: 5px;
  color: #000;
  background-color: #fff;
  overflow: hidden;
}

.footer_menu li {
  padding: 10px;
  cursor: pointer;
  border-bottom: 0.5px solid #cecece;
}

.footer_menu li:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  .footer-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }

  .footer-bar .logo {
    margin-left: 0px;
  }

  .footer-bar .links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-right: 0px;
  }

  .footer-bar .links div {
    height: auto;
  }

  .footer-bar .links .home:hover,
  .footer-bar .links .about:hover,
  .footer-bar .links .services:hover,
  .footer-bar .links .projects:hover,
  .footer-bar .links .contact:hover {
    margin-top: 0px;
    border-top: 0px;
    margin-bottom: -2px;
    border-bottom: 2px solid var(--green);
    color: var(--green);
  }

  .active {
    margin-top: 0px;
    border-top: 0px;
    margin-bottom: -2px;
    border-bottom: 2px solid var(--green);
    color: var(--green) !important;
  }

  .last-section {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    height: 6vh;
    padding-bottom: 1rem;
    margin-top: 50px;
  }

  .copyright-content {
    margin-top: 10px;
  }
}

@media (max-width: 360px) {
  .footer-bar .links {
    gap: 8px;
  }
}
