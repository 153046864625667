.design-mastery-right-sec {
  background-image: url("../../../assets/design-mastery-right.png");
  background-position: center;
  background-size: cover;
  height: 100%;
}

.design-mastery-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 100%; */
  height: 100vh;
  margin-top: 5rem;
}

.design-mastery-text-1,
.design-mastery-text-3,
.design-mastery-text-4-office-addr {
  font-size: 0.9rem;
  font-weight: 400;
  color: var(--lightgrey);
  margin-top: 1.5rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: justify;
}

.design-mastery-text-1 span:hover {
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.design-mastery-text-2 {
  font-family: "Oswald", sans-serif;
  font-size: 3rem;
  font-weight: 400;
  text-align: justify;
}

.design-mastery-left-sec {
  margin: 3rem 0px;
}

.building-img {
  background-image: url("../../../assets/Rectangle.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 15%;
  height: 10vh;
}

.design-mastery-text-4 {
  margin-top: 2.5rem;
  width: 60%;
}

.design-mastery-text-4-office-name {
  font-family: "Oswald", sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  margin-top: 1rem;
  text-align: justify;
}

@media (max-width: 768px) {
  .about_get_in_touch {
    margin-top: 60rem;
  }

  .building-img {
    background-position: center;
    background-size: contain;
    width: 35%;
    height: 10vh;
  }
}
