.pre_loader {
  background-image: url("../../../assets/LoadingScreen.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_wrapper {
  width: 400px;
  position: absolute;
  top: 55%;
}

.loading_percentage {
  position: absolute;
  top: 64%;
  font-size: 20px;
  color: #61ff00;
}

.loader {
  width: 0;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: #61ff00;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  animation: animFw 4s linear infinite;
}

.loader::after,
.loader::before {
  content: "";
  width: 10px;
  height: 1px;
  background: #61ff00;
  position: absolute;
  top: 9px;
  right: -2px;
  opacity: 0;
  transform: rotate(-45deg) translateX(0px);
  box-sizing: border-box;
  animation: coli1 0.3s linear infinite;
}

.loader::before {
  top: -4px;
  transform: rotate(45deg);
  animation: coli2 0.3s linear infinite;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes coli1 {
  0% {
    transform: rotate(-45deg) translateX(0px);
    opacity: 0.7;
  }
  100% {
    transform: rotate(-45deg) translateX(-45px);
    opacity: 0;
  }
}

@keyframes coli2 {
  0% {
    transform: rotate(45deg) translateX(0px);
    opacity: 1;
  }
  100% {
    transform: rotate(45deg) translateX(-45px);
    opacity: 0.7;
  }
}
