.residential-sec-heading {
  font-family: "Oswald", sans-serif;
  font-size: 1.9rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin-top: 7rem;
}

.residential-sec-heading span:hover {
  color: var(--green);
}

.residential-sec-cards {
  margin-top: 3rem;
}

.residential-sec-card-img img {
  width: 100%;
  max-height: 50vh;
}

.residential-sec-card-img {
  width: 31.5%;
}

@media (max-width: 768px) {
  .residential-sec-card-img {
    width: 50%;
  }
}

@media (max-width: 550px) {
  .residential-sec-card-img {
    width: 100%;
  }
}

/* .residential-sec-card-img{
    background-image: url("../../../assets/Frame\ 16.png");
    height: 50vh;
    width: 30%;
    background-position: center;
    background-size: cover;
} */
