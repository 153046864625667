.panoramic-heading-section {
  color: var(--lightgrey);
  margin-top: 3.5rem;
}

.panoramic-image-section {
  background-image: url("../../../assets/panoramic-img.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  margin-top: 2rem;
}
