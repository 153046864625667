.banner_wrapper {
  height: 60vh;
  width: 100%;
  overflow: hidden;
  margin-top: 30px;
}

.banner {
  background-position: center;
  object-fit: cover;
  height: 60vh;
  width: 100%;
}

@media (max-width: 768px) {
  .banner {
    background-position: center;
    background-size: cover;
    height: 35vh;
    margin-top: 50px;
  }
}

/* .banner {
  background-image: url("../../../assets/about-banner-img.png");
  background-position: center;
  background-size: cover;
  height: 70vh;
}

@media (max-width: 768px) {
  .banner {
    background-position: center;
    background-size: cover;
    height: 35vh;
    margin-top: 50px;
  }
} */
