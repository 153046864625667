.what-we-do-section {
  padding: 10% 0;
  position: relative;
  width: 100%;
}

.what-we-do-section-top {
  color: var(--lightgrey);
  text-align: center;
  margin-bottom: 30px;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.5rem;
}

.bottom {
  font-size: 2rem;
  text-align: center;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.what-we-do-section .top_corner {
  position: absolute;
  top: -80px;
  right: 0px;
}

.what-we-do-section_images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.what-we-do-section_images .img_wrapper {
  width: 16rem !important;
  max-width: 16rem;
  height: 35rem !important;
}

.img_front {
  width: 16rem !important;
  height: 35rem !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .what-we-do-section_images {
    flex-direction: column;
  }

  .what-we-do-section_images img:nth-child(1) {
    margin-top: 20px;
  }
}

.card_back_container {
  border: 1px solid white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 16rem;
  height: 35rem;
}

.card_back_container img {
  width: auto;
  height: 10rem;
  margin-top: 30px;
}

.card_back_info_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card_back_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 24px;
}

.what_we_do_card_heading {
  font-family: "Oswald", sans-serif;
  font-weight: lighter;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 5px;
  padding: 0px 5px;
  text-align: center;
  text-transform: uppercase;
}

.what_we_do_card_para {
  font-family: "Oswald", sans-serif;
  font-weight: lighter;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 1px;
  padding: 0px 5px;
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

.view_more_button_container {
  position: relative;
  margin-bottom: 40px;
}
