.hero_section_wrapper {
  position: relative;
}

.bg-image {
  background-image: url("../../../assets/banner-img.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100svh;
}

.banner-text {
  color: var(--white);
  position: absolute;
  top: 240px;
  width: 100%;
  left: 18%;
}

@media (max-width: 1400px) {
  .banner-text {
    top: 150px;
    left: 18%;
  }
}

@media (max-width: 768px) {
  .banner-text {
    top: 120px;
    left: 12%;
  }
}

@media (max-width: 440px) {
  .banner-text {
    top: 70px;
    left: 0%;
  }
}

.text-1 {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 1rem;
  margin-left: 0.5rem;
  font-family: "Didact Gothic", sans-serif;
}
.text-1 span:hover {
  border-top: 2px solid var(--green);
  border-bottom: 2px solid var(--green);
}
.text-2 {
  font-size: 3.7rem;
  font-weight: 400;
  margin-top: 0.7rem;

  font-family: "Oswald", sans-serif;
}
.text-3 {
  font-size: 1rem;
  font-weight: 100;
  margin-top: 0.7rem;
  margin-left: 0.5rem;
  font-family: "Didact Gothic", sans-serif;
}

.top_corner {
  position: absolute;
  right: 0px;
}

@media (max-width: 440px) {
  .top_corner {
    display: none;
  }
}

.view_project_btn {
  position: absolute;
  left: 0px;
}
