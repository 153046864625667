.whoweare-section {
  display: flex;
}

.whoweare-left-section {
  background-image: url("../../../assets/whoweare-left-img.png");
  background-position: center;
  background-size: cover;
  /* width: 50%; */
  height: 80vh;
  margin-top: 6rem;
}

.whoweare-right-section {
  margin-top: 5rem;
  text-align: center;
}

.whoweare-right-section-content {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 2rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: justify;
}

.whoweare-right-section-content div {
  font-family: "Oswald", sans-serif;
  font-size: 3rem;
  font-weight: 400;
}

.whoweare_MOBimg {
  display: none;
}

@media (max-width: 768px) {
  .whoweare-left-section {
    display: none;
  }

  .whoweare-right-section {
    margin-top: -60px;
  }

  .whoweare_MOBimg {
    display: block;
    height: 50vh;
    width: 100%;
    object-fit: cover;
    object-position: right;
    border-radius: 0px 20px 0px 0px;
  }
}
