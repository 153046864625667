.intruducing-section {
  margin-top: 7.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.bottom-most {
  text-align: center;
  margin-top: 1.5rem;
}

.get-in-touch-btn {
  position: relative;
}
