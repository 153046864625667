.get-in-touch-section {
  margin-top: -100px;
  margin-bottom: 40px;
}

.get-in-touch-heading {
  font-size: 2rem;
  color: #cfcfcf;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}

.get-in-touch-form label {
  margin-top: 25px;
}

.get-in-touch-form input:focus,
.get-in-touch-form textarea:focus {
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #777777;
}

.get-in-touch-form input {
  /* background-color: var(--darkgrey); */
  background-color: transparent;
  color: #757575;
  border: 1px solid #444444;
  border-radius: 0px !important;
}

.get-in-touch-form textarea {
  /* background-color: var(--darkgrey); */
  background-color: transparent;
  color: #757575;
  border: 1px solid #444444;
  border-radius: 0px !important;
}

.get-in-touch-form input::placeholder,
.get-in-touch-form textarea::placeholder {
  color: #757575;
}

.get-in-touch-form label {
  margin-bottom: 0.5rem;
}

.get-in-touch {
  margin-top: 12rem;
}

.get-in-touch-form {
  margin-top: 2rem;
}

.couch-img_wrapper {
  margin-top: 16rem;
  overflow: hidden;
}

.couch-img {
  background-position: center;
  background-size: cover;
  width: 100%;
}

.get-in-touch_btn {
  margin-top: 50px !important;
  border-radius: 0px;
  padding: 6px 30px;
  color: #d5d5d5 !important;
  background-color: transparent !important;
  border: 0.5px solid #d5d5d5 !important;
}

.get-in-touch_btn:hover {
  color: #fff !important;
  background-color: #a5a5a5 !important;
}

@media (max-width: 768px) {
  .get-in-touch-section {
    margin-top: -200px;
  }

  .get-in-touch {
    background-image: url("../../../assets/Living room interior set with couch.png");
    background-repeat: no-repeat;
    background-position: bottom 10px left 100px;
  }

  .couch-img_wrapper {
    display: none;
  }
}

@media (max-width: 550px) {
  .get-in-touch-form label {
    display: none;
  }

  .get-in-touch-form input {
    margin-bottom: 25px;
  }
}
