@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Oswald:wght@200;300;400&display=swap");

.view-project-btn {
  border: 1px solid var(--lightgrey);
  padding: 0.4rem;
  background-color: var(--white);
  width: 128px;
  height: 40px;
  text-align: center;
  margin-top: 2rem;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: "Didact Gothic", sans-serif;
  font-size: 12px;
  color: var(--black) !important;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  line-height: 18.6%;
  letter-spacing: 0.5px;
}

.view-project-btn:hover {
  border: 1px solid var(--lightgrey);
  background-color: var(--black);
  color: var(--white) !important;
}

.view-project-btn a {
  color: inherit;
  text-decoration: none;
}

.view-project-btn::after,
.view-project-btn::before {
  transition: all 0.3s ease-in-out;
}

.view-project-btn::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 140px;
  border-bottom: 1px solid var(--lightgrey);
}

.view-project-btn::before {
  content: "";
  border-right: 1px solid var(--lightgrey);
  position: absolute;
  top: 55%;
  bottom: -21px;
  left: 143.5px;
}

@keyframes moveAfter {
  0% {
    width: 140px;
  }
  100% {
    width: 0px;
  }
}

@keyframes moveBefore {
  0% {
    bottom: -21px;
  }
  100% {
    bottom: 100px;
  }
}

@keyframes reverseMoveAfter {
  0% {
    width: 0px;
  }
  100% {
    width: 140px;
  }
}

@keyframes reverseMoveBefore {
  0% {
    bottom: 100px;
  }
  100% {
    bottom: -21px;
  }
}

.view-project-btn:hover::after {
  animation: moveAfter 0.3s ease-in-out forwards;
}

.view-project-btn:hover::before {
  animation: moveBefore 0.3s ease-in-out forwards;
}

.view-project-btn:not(:hover)::after {
  animation: reverseMoveAfter 0.3s ease-in-out forwards;
}

.view-project-btn:not(:hover)::before {
  animation: reverseMoveBefore 0.3s ease-in-out forwards;
}
