.custom_navbar_color {
  background-color: #000 !important;
}

.custom_navbar_color .links .home,
.custom_navbar_color .links .about,
.custom_navbar_color .links .services,
.custom_navbar_color .links .projects,
.custom_navbar_color .links .contact {
  color: rgba(255, 255, 255, 0.55);
  text-decoration: none;
  margin-right: 10px;
}

.custom_navbar_color .links .home:hover,
.custom_navbar_color .about:hover,
.custom_navbar_color .services:hover,
.custom_navbar_color .projects:hover,
.custom_navbar_color .contact:hover {
  margin-top: -2px;
  border-top: 2px solid var(--green);
  color: var(--green);
}

.custom_navbar_color .nav-link:hover {
  margin-top: -2px;
  border-top: 2px solid var(--green);
  color: var(--green);
}

.custom_navbar_color .offcanvas-header .btn-close {
  background-color: aliceblue;
  border-radius: 100%;
}

.active {
  margin-top: -2px;
  border-top: 2px solid var(--green);
  color: var(--green) !important;
}

.social-network-icon {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .custom_navbar_color .links .home:hover,
  .custom_navbar_color .about:hover,
  .custom_navbar_color .services:hover,
  .custom_navbar_color .projects:hover,
  .custom_navbar_color .contact:hover {
    margin-top: 0px;
    border-top: 0px;
    margin-bottom: -2px;
    border-bottom: 2px solid var(--green);
    color: var(--green);
  }

  .custom_navbar_color .services:hover {
    margin-top: 0px;
    border-top: 0px;
    margin-bottom: 0px;
    border-bottom: 0px;
    color: var(--green);
  }

  .custom_navbar_color .nav-link:hover {
    margin-top: 0px;
    border-top: 40px;
  }

  .active {
    margin-top: 0px;
    border-top: 0px;
    margin-bottom: -2px;
    border-bottom: 2px solid var(--green);
    color: var(--green) !important;
  }

  .custom_navbar_color .social-network-icon {
    padding-top: 10px;
  }

  .mob_offcanvas_body {
    margin-top: 100px;
  }

  .custom_navbar_color .links .home,
  .custom_navbar_color .links .about,
  .custom_navbar_color .links .services,
  .custom_navbar_color .links .projects,
  .custom_navbar_color .links .contact {
    padding: 20px;
  }

  .social-network-icon {
    margin-left: 20px;
    display: flex;
    gap: 20px;
  }
}
