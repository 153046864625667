.WhyToChoose-section {
  margin-top: 50px;
}

.bottom-most-line {
  font-size: 0.8rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
  margin-top: 20px;
}

.WhyToChoose-section-last-content {
  font-size: 0.8rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
}
