@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400&display=swap");

section {
  margin-top: 70px;
}

.choiceSelector {
  margin: 20px 0px 50px 0px;
  width: 100%;
  text-align: center;
}

.choiceSelector_btn {
  width: 250px;
  border: 0px !important;
  outline: none;
  text-transform: uppercase;
  background-color: #3b3b3b;
  border-radius: 20px;
  padding: 10px;
  font-family: "Oswald", sans-serif;
  letter-spacing: 2px;
}

.choiceSelector_wrapper .btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: #1e1e1e !important;
  color: #61ff00;
}

.choiceSelector_btn:hover {
  border: 0px !important;
  background-color: #3e3e3e !important;
}

.services_row-text-1,
.services_row-text-3 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--lightgrey);
  margin-top: 2rem;
  font-family: "Didact Gothic", sans-serif;
  text-align: justify;
}

.services_row-text-1 span:hover {
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.services_row-text-2 {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 400;
  color: var(--lightgrey);
}

.services_row-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 30px;
}

.services_row-icons .icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 10rem;
  height: 10rem;
}

.services_row-icons .icons:hover {
  border-bottom: 2px solid var(--green);
}

.services_row .icons p {
  font-size: 0.8rem;
  color: var(--lightgrey);
  height: 15vh;
  text-align: center;
  font-family: "Oswald", sans-serif;
}

.services_row .icon_heading {
  font-size: 16px !important;
}

.services_row .underText {
  margin-top: -20px;
  font-weight: lighter;
}

.services_row-img {
  width: 100%;
  height: auto;
}

.three_sixty-texts-and-icons .row {
  padding: 0px !important;
  --bs-gutter-x: 0px !important;
  --bs-gutter-y: 0px !important;
}
.three_sixty-icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.three_sixty-icons .icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 10rem;
  height: 300px;
  margin-top: 2rem;
}

.three_sixty-icons .icons:hover {
  border-bottom: 2px solid var(--green);
}

.three_sixty-icons .underText {
  margin-top: -70px;
  font-weight: lighter;
}

.architecture_img_wrapper {
  width: 100%;
}

.architecture_img {
  object-position: center;
  object-fit: cover;
  width: 100%;
}

.mobile-visible {
  display: none;
}

.showcase {
  margin-bottom: 50px;
}

.section_heading {
  font-size: 2rem;
  color: #cfcfcf;
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 70px;
}
.section_heading2 {
  margin-top: 70px;
}

.green_text_heading {
  color: var(--green);
}

@media (max-width: 768px) {
  section {
    margin-top: 0px;
  }

  .services_row-icons {
    gap: 20px;
  }

  .services_row-icons > * {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
    box-sizing: border-box;
  }

  .three_sixty-icons {
    grid-template-columns: 1fr;
    row-gap: 50px;
  }

  .three_sixty-icons .icons {
    width: 75%;
    height: 250px;
  }

  .three_sixty-icons .underText {
    margin-top: 0px;
  }

  .services_row-img {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    height: 70vh;
    object-position: left;
    object-fit: cover;
  }

  .mobile-visible {
    display: block;
    height: 70vh;
  }

  .mobile-hidden {
    display: none;
  }
}

@media (max-width: 550px) {
  section {
    margin-top: -120px;
  }

  .choiceSelector_btn {
    width: 200px;
    padding: 8px;
  }
}

@media (max-width: 360px) {
  .choiceSelector_btn {
    width: 170px;
    padding: 8px;
  }
}
