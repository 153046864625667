.our-projects {
  text-align: center;
  width: 100%;
}

.our-projects-top {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 40px;
  margin-top: 8rem;
  margin-bottom: 30px;
}

.view-projects-btn_container {
  position: relative;
}

@media (max-width: 500px) {
  .our-projects-top {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.our-projects-heading {
  font-size: 2.3rem;
  font-weight: 300;
  font-family: "Oswald";
}

.our-projects-image {
  margin: 0px 2rem;
}
